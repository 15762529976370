import { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import Plot from "react-plotly.js";
import { format, addHours, subHours, subDays, startOfDay, endOfDay } from "date-fns";
import { format as formatTZ } from "date-fns-tz";
import client from "../../api/client";
import { useAuth } from "../../hooks/use-auth";
import { useCanopy as _useCanopy } from "../../utils/displaySettingUtil";
import { convertToVaporPressureDeficit } from "../../utils/calcUtil";

import "./DetailPlot.css";

const ENV_INFO_PLOT_CONFIG = {
  air_temperature: {
    displayName: "代表温度",
    hovertemplate: "%{y} ℃",
    color: '#f4cae4',
  },
  relative_humidity: {
    displayName: "相対湿度",
    hovertemplate: "%{y} %RH",
    color: '#cbd5e8',
  },
  co2_concentration: {
    displayName: "CO2濃度",
    hovertemplate: "%{y} ppm",
    color: '#b3e2cd',
  },
  solar_irradiance: {
    displayName: "日射",
    hovertemplate: "%{y} W/m2",
    color: '#fdcdac',
  },
  soil_volumetric_water_content: {
    displayName: "土壌水分",
    hovertemplate: "%{y} %",
    color: '#f1e2cc',
  },
  soil_electrical_conductivity: {
    displayName: "土壌電気伝導度",
    hovertemplate: "%{y} dS/m",
    color: '#f1e2cc',
  },
  soil_temperature: {
    displayName: "土壌温度",
    hovertemplate: "%{y} ℃",
    color: '#f1e2cc',
  },
  vapor_pressure_deficit: {
    displayName: "飽差",
    hovertemplate: "%{y} g/m3"
  }
};

const ENV_INFO_NAMES = Object.keys(ENV_INFO_PLOT_CONFIG);

const SP2_INFO_PLOT_CONFIG = {
  "eggplant": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "chinese-chive": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A", },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fresh_weight: { displayName: "新鮮重", hovertemplate: "%{y} g/m2", color: "#01A99D" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    leaf_length: { displayName: "草丈", hovertemplate: "%{y} cm", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "bell-pepper": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "tomato": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "melon": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "grape": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "spinach": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A", },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fresh_weight: { displayName: "新鮮重", hovertemplate: "%{y} g/m2", color: "#01A99D" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    leaf_length: { displayName: "草丈", hovertemplate: "%{y} cm", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "wasabi": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A", },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fresh_weight: { displayName: "新鮮重", hovertemplate: "%{y} g/m2", color: "#01A99D" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    leaf_length: { displayName: "草丈", hovertemplate: "%{y} cm", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "strawberry": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "cucumber": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "green-pepper": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "green-pepper-s": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "corn": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
  "mandarin-orange": {
    lai: { displayName: "LAI", hovertemplate: "%{y} m2/m2", color: "#39B54A" },
    photosynthetic_rate: { displayName: "光合成速度", hovertemplate: "%{y} µmol/m2/s", color: "#FF1D25" },
    fruit_load: { displayName: "着果負担", hovertemplate: "%{y} ", color: "#93278F" },
    transpiration_rate: { displayName: "蒸散速度", hovertemplate: "%{y} mmol/m2/s", color: "#3FA9F5" },
    number_of_blooms: { displayName: "開花数", hovertemplate: "%{y} 個/m2", color: "#FF7BAC" },
    leaf_temperature: { displayName: "葉温", hovertemplate: "%{y} ℃", color: "#D4686C" },
  },
};

function getInfoOptions(cropName) {
  const selectEnvInfoOptions = Object.entries(ENV_INFO_PLOT_CONFIG)
    .map(([infoName, config]) => ({ value: infoName, label: config.displayName }));
  const selectSP2InfoOptions = Object.entries(SP2_INFO_PLOT_CONFIG[cropName])
    .map(([infoName, config]) => ({ value: infoName, label: config.displayName }))
  return selectEnvInfoOptions.concat(selectSP2InfoOptions);
}

function getSP2InfoName(displaySetting, infoName) {
  if (!_useCanopy(displaySetting)) return infoName;
  if ([ "photosynthetic_rate", "transpiration_rate", "leaf_temperature" ].includes(infoName)) {
    return "canopy_" + infoName;
  }
  return infoName;
}

function mergeArrays(array1, array2) {
  const result = [[], []];

  const uniqueDates = Array.from(new Set([...array1[0], ...array2[0]]));

  uniqueDates.forEach(date => {
    const index1 = array1[0].indexOf(date);
    const index2 = array2[0].indexOf(date);

    if (index1 !== -1 && index2 !== -1) {
      result[0].push(date);
      result[1].push([array1[1][index1], array2[1][index2]]);
    }
  });

  return result;
}

async function getVaporPressureDeficitData(selectedInfoNames, srcData, groupName, houseName, startDate, endDate, samplingStep, samplingInterval) {

    const targetInfoNames = [];
    const baseData = {
      "air_temperature": srcData["air_temperature"],
      "relative_humidity": srcData["relative_humidity"]
    };

    if (!selectedInfoNames.includes("air_temperature")) {
      targetInfoNames.push("air_temperature");
    }

    if (!selectedInfoNames.includes("relative_humidity")) {
      targetInfoNames.push("relative_humidity");
    }

    const params = {
      group_name: groupName,
      house_name: houseName,
      start: startOfDay(new Date(startDate)).toISOString(),
      end: endOfDay(new Date(endDate)).toISOString(),
      sampling_step: samplingStep,
      sampling_interval: samplingInterval,
    };

    for (const targetInfoName in targetInfoNames) {
      const url = `/env/${targetInfoName}`;
      try {
        const response = await client.get(url, { params });

        const convertedData = response.data.reduce((resultArray, { timestamp, value }) => {
          resultArray[0].push(formatTZ(new Date(timestamp), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
          resultArray[1].push(value);
          return resultArray;
        }, [[], []]);

        baseData[targetInfoName] = convertedData;
      } catch (error) {
        console.log(error);
      }
    }

    const mergedData = mergeArrays(baseData["air_temperature"], baseData["relative_humidity"]);
    const result = [[], []];
    mergedData[0].forEach((timestamp, index) => {
      result[0].push(timestamp);
      const airTemperature = mergedData[1][index][0];
      const relativeHumidity = mergedData[1][index][1];
      result[1].push(convertToVaporPressureDeficit(airTemperature, relativeHumidity));
    });
    return result;
}

async function getPlotData(displaySetting, groupName, houseName, cropName, selectedInfoNames, startDate, endDate, samplingStep, samplingInterval) {

  const srcData = {};

  const params = {
    group_name: groupName,
    house_name: houseName,
    start: startOfDay(new Date(startDate)).toISOString(),
    end: endOfDay(new Date(endDate)).toISOString(),
    sampling_step: samplingStep,
    sampling_interval: samplingInterval,
  };

  for (const selectedInfoName of selectedInfoNames) {

    if (selectedInfoName === "vapor_pressure_deficit") continue;

    const url = ENV_INFO_NAMES.includes(selectedInfoName)
      ? `/env/${selectedInfoName}`
      : `/sp2/db/${getSP2InfoName(displaySetting, selectedInfoName)}`;

    try {
      const response = await client.get(url, { params });

      const convertedData = response.data.reduce((resultArray, { timestamp, value }) => {
        resultArray[0].push(formatTZ(new Date(timestamp), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
        resultArray[1].push(value);
        return resultArray;
      }, [[], []]);

      srcData[selectedInfoName] = convertedData;
    } catch (error) {
      console.log(error);
    }
  }

  if (selectedInfoNames.includes("vapor_pressure_deficit")) {
    const vaporPressureDeficitData = await getVaporPressureDeficitData(
      selectedInfoNames,
      srcData,
      groupName,
      houseName,
      startDate,
      endDate,
      samplingStep,
      samplingInterval
    );
    srcData["vapor_pressure_deficit"] = vaporPressureDeficitData;
  }

  const mergedPlotConfig = { ...ENV_INFO_PLOT_CONFIG, ...SP2_INFO_PLOT_CONFIG[cropName] };

  return Object.keys(srcData).map((key, i) => ({
    type: "scatter",
    mode: "lines",
    name: mergedPlotConfig[key].displayName,
    x: srcData[key][0],
    y: srcData[key][1],
    yaxis: 0 < i ? `y${i+1}` : "y",
    line: { color: mergedPlotConfig[key].color},
    hovertemplate: mergedPlotConfig[key].hovertemplate,
  }));
};

export default function DetailPlot({ groupName, houseName, cropName, displaySetting }) {

  const { user } = useAuth();
  const SELECTED_INFO_OPTIONS_LOCAL_STORAGE_KEY = `${user.userName}_${houseName}_DetailPlot_selectedInfoOptions`;
  const now = new Date();
  const infoOptions = useMemo(() => getInfoOptions(cropName), [cropName]);

  const [selectedInfoOptions, setSelectedInfoOptions] = useState(infoOptions);
  const [startDate, setStartDate] = useState(format(subDays(now, 7), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(now, "yyyy-MM-dd"));
  const [samplingStep, setSamplingStep] = useState("T");
  const [samplingInterval, setSamplingInterval] = useState(10);
  const [plotData, setPlotData] = useState([]);
  const [plotXaxisRange, setPlotXaxisRange] = useState([
    format(subHours(now, 3), "yyyy-MM-dd HH:mm:ss"),
    format(addHours(now, 3), "yyyy-MM-dd HH:mm:ss")
  ]);
  const [plotSliderRange, setPlotSliderRange] = useState([
    format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
    format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
  ]);

  useEffect(() => {

    const chacedSelectedInfoOptions = localStorage.getItem(SELECTED_INFO_OPTIONS_LOCAL_STORAGE_KEY);
    let _selectedInfoOptions = chacedSelectedInfoOptions ? JSON.parse(chacedSelectedInfoOptions) : infoOptions;
    // 既にlocalStorageに群落項目が保存されている場合があるので除外する
    _selectedInfoOptions = _selectedInfoOptions.filter(({ value }) => !["canopy_photosynthetic_rate", "canopy_transpiration_rate"].includes(value));
    setSelectedInfoOptions(_selectedInfoOptions);

    const infoNames = _selectedInfoOptions.map(({ value }) => value);
    const _now = new Date();
    const _startDate = format(subDays(_now, 7), "yyyy-MM-dd");
    const _endDate = format(_now, "yyyy-MM-dd");
    const _samplingStep = "T";
    const _samplingInterval = 10;
    getPlotData(displaySetting, groupName, houseName, cropName, infoNames, _startDate, _endDate, _samplingStep, _samplingInterval)
      .then(data => setPlotData(data))
      .catch(error => console.log(error));
    return () => {
      setStartDate(_startDate);
      setEndDate(_endDate);
      setSamplingStep(_samplingStep);
      setSamplingInterval(_samplingInterval);
      setPlotData([]);
      setPlotXaxisRange([
        format(subHours(_now, 3), "yyyy-MM-dd HH:mm:ss"),
        format(addHours(_now, 3), "yyyy-MM-dd HH:mm:ss")
      ]);
      setPlotSliderRange([
        format(startOfDay(new Date(_startDate)), "yyyy-MM-dd HH:mm:ss"),
        format(endOfDay(new Date(_endDate)), "yyyy-MM-dd HH:mm:ss")
      ]);
    };
  }, [displaySetting, groupName, houseName]);

  const handleSamplingIntervalChange = event => {
    const value = event.target.value;
    if (0 < value) {
      setSamplingInterval(event.target.value);
    }
  };

  const handleSelectInfoChange = (options) => {
    localStorage.setItem(SELECTED_INFO_OPTIONS_LOCAL_STORAGE_KEY, JSON.stringify(options));
    setSelectedInfoOptions(options);
  };

  const handleGetDataButtonClick = () => {
   const selectedInfoNames = selectedInfoOptions.map(({ value }) => value);
    getPlotData(displaySetting, groupName, houseName, cropName, selectedInfoNames, startDate, endDate, samplingStep, samplingInterval)
      .then(data => {
        setPlotData(data);
        setPlotXaxisRange([
          format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
          format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
        ]);
        setPlotSliderRange([
          format(startOfDay(new Date(startDate)), "yyyy-MM-dd HH:mm:ss"),
          format(endOfDay(new Date(endDate)), "yyyy-MM-dd HH:mm:ss")
        ]);
      })
      .catch(error => console.log(error));
  };

  const handlePlotRelayout = event => {
    if (event["xaxis.range"]) {
      setPlotXaxisRange(event["xaxis.range"]);
    } else if (event["xaxis.range[0]"] && event["xaxis.range[1]"]) {
      setPlotXaxisRange([event["xaxis.range[0]"], event["xaxis.range[1]"]]);
    } else {
      setPlotXaxisRange([]);
    }
  };

  const layout = {
    autosize: true,
    height: 600,
    hovermode: "x unified",
    xaxis :{
      type: "date",
      range: plotXaxisRange,
      rangeslider: {
        visible: true,
        range: plotSliderRange
      },
      rangeselector: {
        buttons: [
          {
            step: 'hour', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 6,
            label: "6h"
          },
          {
            step: 'day', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 1,
            label: "1日"
          },
          {
            step: 'day', //| 'month' | 'year' | 'all';
            stepmode: 'backward',
            count: 7,
            label: "7日"
          },
          {
            step: "all",
            stepmode: "backward",
            label: "ALL"
          }
        ]
      }
    },
    yaxis: {
      visible: false,
    },
    yaxis2: {
      overlaying: 'y',
      visible: false,
    },
    yaxis3: {
      overlaying: 'y',
      visible: false,
    },
    yaxis4: {
      overlaying: 'y',
      visible: false,
    },
    yaxis5: {
      overlaying: 'y',
      visible: false,
    },
    yaxis6: {
      overlaying: 'y',
      visible: false,
    },
    yaxis7: {
      overlaying: 'y',
      visible: false,
    },
    yaxis8: {
      overlaying: 'y',
      visible: false,
    },
    yaxis9: {
      overlaying: 'y',
      visible: false,
    },
    yaxis10: {
      overlaying: 'y',
      visible: false,
    },
    yaxis11: {
      overlaying: 'y',
      visible: false,
    },
    yaxis12: {
      overlaying: 'y',
      visible: false,
    },
    yaxis13: {
      overlaying: 'y',
      visible: false,
    },
    yaxis14: {
      overlaying: 'y',
      visible: false,
    },
  };

  return (
    <div className="detail-plot-container">
      <div className="detail-plot-title">
        <div className="detail-plot-title-text">観測データ</div>
      </div>
      <div className="detail-plot-input-area">
        <div className="detail-plot-input-item">
          <p>取得項目：</p>
          <Select
            isMulti
            closeMenuOnSelect={false}
            options={infoOptions}
            value={selectedInfoOptions}
            onChange={handleSelectInfoChange}
          />
        </div>
        <div className="detail-plot-input-item">
          <p>取得範囲：</p>
          <div>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={event => setStartDate(event.target.value)}
            />
            <span> 〜 </span>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={event => setEndDate(event.target.value)}
            />
          </div>
        </div>
        <div className="detail-plot-input-item">
          <p>取得間隔：</p>
          <div>
            <input
              type="number"
              min="1"
              step="1"
              value={samplingInterval}
              onChange={handleSamplingIntervalChange}
            />
            <select
              value={samplingStep}
              onChange={event => setSamplingStep(event.target.value)}
            >
              <option value="T">分</option>
              <option value="H">時間</option>
              <option value="D">日</option>
            </select>
          </div>
        </div>
      </div>
      <div className="detail-plot-button-area">
        <button onClick={handleGetDataButtonClick}>データ取得</button>
      </div>
      <div className="detail-plot-plot-area">
        <Plot
          data={plotData}
          style={{ width: "100%" }}
          layout={layout}
          onRelayout={handlePlotRelayout}
        />
      </div>
    </div>
  );
}
