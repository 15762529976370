import { useParams } from "react-router-dom";
import { useGroup } from "../../hooks/use-group";
import House from "../shared/House";
import SP2Info from "./SP2Info";
import EnvInfo from "./EnvInfo";
import LatestImage from "./LatestImage";
import DetailPlot from "./DetailPlot";
import "./Detail.css";

export default function Detail() {

  const { houseName } = useParams();
  const { group } = useGroup();
  const house = group.houses.find(house => house.name === houseName);
  const { name, displayName, cultivation, cameras, displaySetting } = house;

  return (
    <>
      <House
        displayName={displayName}
        cropName={cultivation.crop.name}
        cropDisplayName={cultivation.crop.displayName}
        plantingDate={cultivation.plantingDate}
      >
        <div className="detail-container">
          <div className="detail-content">
            <SP2Info
              groupName={group.name} houseName={name}
              cameraName={cameras[0].name}
              cropName={cultivation.crop.name}
              plantingDate={cultivation.plantingDate}
              displaySetting={displaySetting}
            />
          </div>
          <div className="detail-content">
            <EnvInfo
              houseName={name}
              plantingDate={cultivation.plantingDate}
            />
          </div>
          <div className="detail-content">
            <LatestImage
              key={name}
              houseName={name}
              cameras={cameras}
              plantingDate={cultivation.plantingDate}
            />
          </div>
          <div className="detail-content">
            <DetailPlot
              key={name}
              groupName={group.name}
              houseName={name}
              cropName={cultivation.crop.name}
              displaySetting={displaySetting}
            />
          </div>
        </div>
      </House>
    </>
  );
}
